export const colors = {
  green: {
    75: `#4DB165`,
  },
  gray: {
    75: `#F1F5F9`,
  },
  grad1: `linear-gradient(to right, #4DB165, #006493)`,
  grad2: `radial-gradient(at center, rgb(52, 232, 158), rgb(15, 52, 67))`,
};
